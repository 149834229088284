<template>
    <video v-if="this.videopath" width="100%" height="150px" controls>
        <source :src="this.videopath" type="video/mp4">
        <!-- Your browser does not support the video tag. -->
    </video>
</template>

<script>
export default{
    props:{ 
        videopath :  String
    }
}
</script>